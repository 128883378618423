export default [
  {
    title: 'Home',
    i18n: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: "Lidership",
    i18n: 'Lidership',
    route: 'lidershipList',
    icon: 'UsersIcon'
  },
  {
    title: "CentralOfficeList",
    i18n: "CentralOfficeList",
    route: "centralOfficeList",
    icon: "UserIcon"
  },
  {
    title: "TerretoralAdminsList",
    i18n: "TerretoralAdminsList",
    route: "terretoralAdminsList",
    icon: "UsersIcon"
  },
  {
    title: "organizationMembersList",
    i18n: "OrganizationMembersList",
    route: "organizationMembersList",
    icon: "UsersIcon"
  },
  {
    title: "requisites",
    i18n: "Requisites",
    route: "requisites",
    icon: "CardListIcon"
  },
  {
    header: "News",
    i18n: "News",
    children: [
      {
        title: "News",
        i18n : "News",
        route: "News"
      },
      {
        title: "Categories",
        i18n: "Categories",
        route: "newsCategories"
      },
      {
        title: "NewsTypes",
        i18n: "NewsTypes",
        route: "newsTypes"
      }
    ]
  },
  {
    title: "Galleries",
    i18n: "Galleries",
    route: "Galleries",
    icon: "FolderIcon"
  }
]
